//override theme defaults
$label-margin-bottom: 0;

//don't show green validation success color
$form-feedback-valid-color: #ced4da;
$form-feedback-icon-valid: "";
$form-select-feedback-icon-padding-right: add(0em);

@import "node_modules/bootstrap/scss/functions";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  vlg: 1200px,
  xl: 1400px,
  xxl: 1920px
);

$spacer: 1rem;

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
);

$spacers: map-merge(
    $spacers, (
    6: $spacer * 3,
  )
);

@import "node_modules/bootstrap/scss/variables";
@import "src/app/new-portvisit/variables";

$info: #17a2b8;
$primary: $blue-700;

$theme-colors: map-merge($theme-colors, (
  "info": $info,
  "primary": $primary,
  "tertiary": $grey-400
));

$pagination-color: map-get($theme-colors, "info");
$pagination-active-bg: map-get($theme-colors, "info");

$link-color: map-get($theme-colors, "info");
$link-hover-color: shade-color($link-color, 50%);
$nav-pills-link-active-bg: map-get($theme-colors, "info");

$tooltip-padding-y: 0.75rem;
$tooltip-padding-x: 0.75rem;

@each $color, $value in $theme-colors {
  .underline-#{$color} {
    text-decoration: underline;
    text-decoration-color: #{$value};
  }
}

@import "node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-body-color: rgb(41, 52, 61);
  --bs-link-color: #17a2b8;
  --bs-border-radius: 0.25rem;
  --bs-nav-link-color: #17a2b8;
  --bs-border-radius-pill: 10rem;
  --bs-link-color-rgb: 23, 162, 184;
  --bs-border-color-translucent: #DAE1E6;

  .new-portvisit {
    --bs-font-sans-serif: "Inter", sans-serif;
  }
}

.popover {
  --bs-popover-max-width: 100vw;
}

.badge {
  --bs-badge-padding-y: 0.25em;
  --bs-badge-padding-x: 0.6em;
}

.nav {
  --bs-nav-link-hover-color: #0f6674;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  background-color: #e9ecef;
}

a,
a.navbar-brand:hover,
.nav-link:hover, .nav-link:focus,
.page-link:hover,
.dropdown-item:hover, .dropdown-item:focus {
  text-decoration: none;
}

a:hover {
  color: $info;
  text-decoration: underline;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #ced4da;
  //padding-right: calc(1.5em + 0.75rem) !important;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control, .form-select {
  --bs-border-color: #ced4da;
}

.nav-link {
  padding: 0.5rem 1rem;
}

[class*="-info "], [class$="-info"] {
  color: #ffffff;
}

[class*="-outline-info "], [class$="-outline-info"],
[class*="text-info "], [class$="text-info"]{
  color: $info !important;
}

[class*="-outline-info "]:hover, [class$="-outline-info"]:hover {
  color: #fff !important;
  background-color: $info !important;
  border-color: $info !important;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 1.25rem;
  margin-right: 0.75rem;

  .form-check-input {
    margin-left: -1.25rem;
  }

  label {
    padding-left: 5px;
  }
}

.alert-info {
  color: #0c5460 !important;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
