@import "bootstrap-overrides";

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: $input-color !important;
}

//for compare directive
input,  textarea,  select,  input:disabled,  app-radio div:first-child,  app-radio div.bg-transparent {
  &.has-diff, .has-diff & {
    background-color: tint-color($warning, 80%) !important;
  }
}
div.checkbox {
  &.has-diff, & .has-diff {
    background-color: tint-color($warning, 80%);
    margin-left: -1.5rem;
    height: 1.5rem;
    input[type=checkbox] {
      margin-left: 0.25rem;
    }
  }
}

// introduce Portbase colors
// background
.bg-portbase-blue {
  background: #003B84 !important;
}
a.bg-portbase-blue:hover, a.bg-portbase-blue:focus {
  background-color: #012838 !important;
}

.bg-portbase-green {
  background-color: #27ae60 !important;
}
a.bg-portbase-green:hover, a.bg-portbase-green:focus {
  background-color: #135a31 !important;
}

.bg-portbase-red {
  background-color: #e74c3c !important;
}
a.bg-portbase-red:hover, a.bg-portbase-red:focus {
  background-color: #a3362a !important;
}

.bg-portbase-orange {
  background-color: #ec6726 !important;
}
a.bg-portbase-orange:hover, a.bg-portbase-orange:focus {
  background-color: rgb(129, 44, 35) !important;
}

.bg-portbase-light-blue {
  background-color: #82cadd !important;
}

a.bg-portbase-light-blue:hover, a.bg-portbase-light-blue:focus {
  background-color: #3492ac !important;
}

.modal {
  overflow: auto !important;
}

.modal-backdrop {
  z-index: 1045;
}

.modal:not(.modal-xl) .modal-dialog {
  z-index: 1050;
  top: 20%;
}

.dropdown-menu{
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1030;
}

// text
.text-portbase-light-blue {
  color: #82cadd !important;
}
.text-portbase-blue {
  color: #004462 !important;
}
.text-portbase-red {
  color: #e74c3c !important;
}
.text-portbase-green {
  color: #27ae60 !important;
}
.text-portbase-orange {
  color: #ec6726 !important;
}
.text-portbase-grey {
  color: #C0C0C0 !important;
}

.no-transition {
  transition: none !important;
}

.narrow-gutters,
.narrow-gutters.row {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

// declaration menu
.declaration-block h2 {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
  padding: 5px 0;
  margin: 20px 0 10px;
  border-bottom: 2px solid #666;
}

.required::after {
  padding-left: 0.25rem;
  content: '*';
}


// to disable up down arrows in input fields of type number
.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance:textfield !important;
}

.z-top {
  z-index: 10000;
}


// drag and drop cursors
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active, .gu-mirror .grabbable {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* automatically show validation feedback in custom components */
.was-validated {
  .ng-invalid + .invalid-feedback, .invalid.invalid-feedback, .valid.valid-feedback {
    display: block !important;
  }

  /* for cases where custom components set 'ng-invalid' manual */
  .ng-invalid.invalid-feedback, .ng-invalid > .invalid-feedback{
    display: block !important;
  }

  .validatable.ng-invalid{
    @extend .border;
    @extend .border-danger;
    @extend .rounded;
  }

    //hide valid validation
  .form-control:valid, .form-control.is-valid {
    padding-right: 0.75rem !important;
  }

  .form-control:invalid, .form-control.is-invalid {
    padding-right: 1.75rem !important;
  }

  .btn-invalid {
    @extend .btn-danger
  }

  .border-invalid {
    @extend .border-danger
  }

  .text-invalid {
    @extend .text-danger
  }
}

/* fixes for ie styling */
label.btn {
  cursor: pointer;
}

input:disabled::placeholder,
input:read-only::placeholder {
  opacity: 0 !important;
}

.fieldset.disabled,
.fieldset.disabled .enabled .fieldset.disabled {
  select, input, textarea, ul, a, button, .btn {
    pointer-events: none;
  }

  .form-control {
    background-color: #e9ecef;
    opacity: 1;
  }

  .form-select {
    color: #6c757d;
    background-color: #e9ecef;
  }

  .btn {
    opacity: 0.65;
  }

  label{
    color: #6c757d;
  }

  input + label{
    color: lightgrey;
  }

  input[type=radio]:checked:after{
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #6c757d;
    content: '';
    display: block;
    visibility: visible;
    border: 2px solid white;
  }

  //make sure button labels still work
  label.btn {
    opacity: 1;
    pointer-events: auto !important;
    cursor: auto;
  }

  .enabled, .always-enabled {
    select, input, textarea, ul, a, button, .btn {
      pointer-events: auto !important;
    }
    .form-control {
      background-color: #ffffff;
    }
    .form-select {
      color: #495057;
      background-color: #ffffff;
    }
    .btn{
      opacity: 1;
      &:disabled {
        opacity: 0.65;
      }
    }
    label{
      color:$input-color;
    }

    input[type=radio]:checked:after{
      content:none;
    }

  }
}

.fieldset.disabled .fieldset.disabled .enabled {
  select, input, textarea, ul, a, button, .btn {
    pointer-events: none;
  }

  .form-control {
    background-color: #e9ecef;
    opacity: 1;
  }

  .form-select {
    color: #6c757d;
    background-color: #e9ecef;
  }

  .btn {
    opacity: 0.65;
  }

  label{
    color: #6c757d;
  }

  input + label{
    color: lightgrey;
  }

  input[type=radio]:checked:after{
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #6c757d;
    content: '';
    display: block;
    visibility: visible;
    border: 2px solid white;
  }

  //make sure button labels still work
  label.btn {
    opacity: 1;
    pointer-events: auto !important;
    cursor: auto;
  }

  .always-enabled {
    select, input, a, textarea, ul, a, button, .btn {
      pointer-events: auto !important;
    }
    .form-control {
      background-color: #ffffff;
    }
    .form-select {
      color: #495057;
      background-color: #ffffff;
    }
    .btn{
      opacity: 1;
      &:disabled {
        opacity: 0.65;
      }
    }
    label{
      color:$input-color;
    }

    input[type=radio]:checked:after{
      content:none;
    }

  }
}

input[type=text]::-ms-clear {
  display: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

::placeholder {
  color: lightgray !important;
}

#beamerSelector {
  display: none !important;
}

.beamer_icon.active, #beamerIcon.active {
  background-color: #e52938 !important;
}

.w-0 {
  width: 0 !important;
}

.mw-0 {
  min-width: 0 !important;
}

.hover-show {

  & .to-show {
    display: none;
  }

  &:hover .to-show {
    display: inherit;
  }
}

.dropdown-menu-flex.show {
  display: flex;
}

.reset-icon {
  color: $blue-700;

  &:hover {
    background-color: $blue-100 !important;
  }
}
