// PB primary colors
$pb-darkblue: rgb(0, 68, 120);
$pb-turquoise: rgb(25, 162, 174);
$pb-orange: rgb(255, 79, 0);
// PB secondary colors
$pb-aqua: rgb(142, 209, 223);
$pb-red: rgb(229, 41, 56);
$pb-darkred: rgb(126, 0, 63);
$pb-fuchsia: rgb(231, 15, 122);
// Greys
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$grey-100: rgb(246, 248, 249);
$grey-200: rgb(230, 234, 237);
$grey-300: rgb(218, 225, 230);
$grey-400: rgb(195, 205, 213);
$grey-500: rgb(141, 155, 165);
$grey-600: rgb(90, 104, 114);
$grey-700: rgb(67, 80, 91);
$grey-800: rgb(41, 52, 61);
$grey-900: rgb(21, 29, 36);
// Blue
$blue-100: rgb(215, 229, 251);
$blue-200: rgb(172, 201, 245);
$blue-300: rgb(129, 173, 239);
$blue-400: rgb(86, 145, 233);
$blue-500: rgb(43, 117, 227);
$blue-600: rgb(0, 89, 221);
$blue-700: rgb(0, 74, 177);
$blue-800: rgb(0, 59, 132);
$blue-850: rgb(0, 35, 87);
$blue-900: rgb(0, 27, 66);
// Green
$green-100: rgb(223, 254, 213);
$green-200: rgb(203, 245, 188);
$green-300: rgb(180, 239, 159);
$green-400: rgb(144, 215, 118);
$green-500: rgb(118, 212, 84);
$green-600: rgb(78, 191, 37);
$green-700: rgb(50, 169, 5);
$green-800: rgb(34, 108, 6);
$green-900: rgb(21, 68, 4);
// Yellow
$yellow-100: rgb(255, 246, 209);
$yellow-200: rgb(255, 232, 138);
$yellow-300: rgb(255, 224, 98);
$yellow-400: rgb(255, 218, 66);
$yellow-500: rgb(255, 212, 34);
$yellow-600: rgb(247, 199, 2);
$yellow-700: rgb(237, 180, 3);
$yellow-800: rgb(153, 117, 0);
$yellow-900: rgb(91, 71, 1);
// Red
$red-100: rgb(253, 224, 224);
$red-200: rgb(251, 192, 192);
$red-300: rgb(248, 149, 149);
$red-400: rgb(244, 82, 82);
$red-500: rgb(242, 54, 54);
$red-600: rgb(238, 0, 0);
$red-700: rgb(196, 0, 0);
$red-800: rgb(147, 0, 0);
$red-900: rgb(92, 0, 0);
